'use client';
import React from 'react';

import { BlockMapping } from '@/blocks/base';
import dynamic from 'next/dynamic';
const BaseBlock = dynamic(() => import("./BaseBlock"));
const HowWeDoIt = dynamic(() => import("./howWeDoIt"));
const SliderBlock = dynamic(() => import('./SliderBlock'));
const Pricing = dynamic(() => import('./Pricing'));
const ContactUs = dynamic(() => import('./ContactUs'));
const OurImpact = dynamic(() => import('./OurImpact'))
const AboutMe = dynamic(() => import('./PersonalInfo'))
const SecondInformation = dynamic(() => import('./SecondInformation'))


const mapping: BlockMapping = {
    baseBlock: { component: BaseBlock },
    howWeDoIt: { component: HowWeDoIt },
    sliderBlock: { component: SliderBlock },
    pricing: { component: Pricing },
    contactUs: { component: ContactUs },
    ourImpact: { component: OurImpact },
    aboutMe: { component: AboutMe },
    secondInformation: { component: SecondInformation },

};

export default mapping;

export function renderBlock(block: any, index = 0, mappings = mapping) {
    if (!block) {
        return null;
    }

    const blockConfig = mappings[block._type as keyof typeof mappings];

    if (!blockConfig) {
        return null;
    }

    const Component = blockConfig.component as React.FC<any>;

    return <Component key={index} {...block} />;
}